<template>
  <!--线型图-->
  <div>
    <div :id="id" :style="`height: ${height}px`" class="chart" />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: 'chart',
    },
    // 标题
    title: {
      type: String,
      default: '',
    },
    left: {
      type: String,
      default: '',
    },
    // 图表数据
    data: {
      type: Object,
      default: () => {},
    },
    // 图表高度
    height: {
      type: Number,
      default: 200,
    },
    // 图表区域上侧边距
    gridTop: {
      type: Number,
      default: 40
    },
    // 图表区域左侧边距
    gridLeft: {
      type: Number,
      default: 20,
    },
    // 图表区域右侧边距
    gridRight: {
      type: Number,
      default: 20,
    },
    // true：曲线，false：折线
    smooth: {
      type: Boolean,
      default: true,
    },
    rotate:{
      type: Number,
      default: 0,
    },
    // 图表类型
    type: {
      type: String,
      default: 'line',
    },
    // 是否显示图例
    legendShow: {
      type: Boolean,
      default: true,
    },
    // 线条颜色
    linecolor: {
      type: Array,
      default: () => ['#006df0', '#0acf97', '#fc7f03', '#d149fe', '#fe6d65', '#fcd02d', '#9ad864'],
    },
    // 平均线
    markLine: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 图表配置
      optionEcharts: {
        // 标题
        title: {
          text: this.title,
          left: this.left,
          textStyle:{
            fontSize: 12,
            color:'#000000'
          },
        },
        // 提示框
        tooltip: {
          // show:false, // 提示框不显示
          trigger: 'axis',
          confine: false, // 限制在图表区域内
        },
        // 图例
        legend: {
          show: this.legendShow,
          // itemWidth: 6,
          // itemHeight: 6,
          textStyle: {
            // color: '#ffffff'
          },
          data: [],
          right: '0%',
        },
        // 数据集
        dataset: {
          source: this.data.source,
        },
        // 图表区域大小
        grid: {
          top: this.gridTop,
          left: this.gridLeft,
          right: this.gridRight,
          bottom: '0',
          containLabel: true, // 包含标签
        },
        // X轴
        xAxis: {
          type: 'category',
          name: '', // 设置为空字符串
          // nameTextStyle: {
          //   show: false // 设置不显示名称
          // },
          axisLabel: {
            textStyle: {
              fontSize: 20, // 字体大小
              fontFamily: 'Arial' // 字体类型
            }
          },
          data: null
        },
        // y轴
        yAxis: {
          type: 'value',
          name: '', // y轴名称
          offset: 0, // 偏移
          position: 'left', // 位置
          axisLabel: {
            formatter: '{value}',
          },
          nameTextStyle: {
            // color: '#fff'
          },
          // 刻度线
          axisTick: {
            show: false,
          },
          // 坐标轴线
          axisLine: {},
          // x轴网格线颜色
          splitLine: {},
        },
        // 系列
        series: [],
      },
    };
  },
  watch: {
    // 监听数据集
    data: {
      deep: true,
      handler(val) {
        // console.log(val)
        this.optionEcharts.dataset.source = val.source;
        this.initChart();
      },
    },
  },
  mounted() {
    this.charData(); // 处理数据
    this.initChart(); // 初始化echarts实例
  },
  methods: {
    charData() {
      // 提示框
      this.optionEcharts.tooltip = this.data.tooltip || this.optionEcharts.tooltip;
      // y轴数据
      this.optionEcharts.yAxis = this.data.yAxis || this.optionEcharts.yAxis;
      this.optionEcharts.xAxis = this.data.xAxis || this.optionEcharts.xAxis;

      // // X轴
      // this.optionEcharts.xAxis = {
      //   type: 'category',
      //   name: '', // 设置为空字符串
      //   // nameTextStyle: {
      //   //   show: false // 设置不显示名称
      //   // },
      //   axisLabel: {
      //     textStyle: {
      //       fontSize: 10, // 字体大小
      //       fontFamily: 'Arial' // 字体类型
      //     }
      //   },
      //   data: null
      // };
      // 系列
      this.data.series.forEach((item, index) => {
        this.optionEcharts.series.push({
          type: item.type, // 图表类型
          markLine: item.markLine, // 平均线
          yAxisIndex: item.yAxisIndex, // 有多条y轴时，设置y轴索引
          color: this.linecolor[index], // 线颜色
          smooth: this.smooth, // 曲线、折线
          symbolSize: '5', // 折点大小
          symbol: "circle",
          barWidth: item.barWidth,
          barMaxWidth: item.barMaxWidth,
          maxBarWidth: item.barMinWidth,
          connectNulls: true,
          itemStyle: item.itemStyle
        });
      });
    },
    initChart() {
      // const char = this.$echarts.init(document.getElementById(this.id))
      const echarts = require('echarts');
      const chartList = document.getElementById(this.id);
      // 初始化echarts实例
      const chart = echarts.init(chartList);
      this.$nextTick(() => {
        // 图表配置
        chart.setOption(this.optionEcharts);
        // 图表的自适应
        window.addEventListener('resize', () => {
          chart.resize();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .chart{
  min-width: 250px;
  width: 100%;
}
</style>
